<mat-card>
  <mat-card-title>Evenement</mat-card-title>

  <mat-card-content>
    <form [formGroup]="eventForm">
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput placeholder="" formControlName="title" required>
          <mat-error *ngIf="eventForm.controls.title.invalid">Name required</mat-error>
        </mat-form-field>
      </p>
<!--      <p>-->
<!--        <mat-form-field appearance="fill">-->
<!--          <mat-label>Description</mat-label>-->
<!--          <textarea matTextareaAutosize placeholder="" formControlName="description"></textarea>-->
<!--          <mat-error *ngIf="eventForm.controls.description.invalid">{{eventForm.controls.description.errors}}</mat-error>-->
<!--        </mat-form-field>-->
<!--      </p>-->
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Date</mat-label>
          <mat-date-range-input [rangePicker]="rangePicker">
            <input matStartDate placeholder="Start date" formControlName="start">
            <input matEndDate placeholder="End date" formControlName="end">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #rangePicker>
            <mat-date-range-picker-actions>
              <button mat-button matDateRangePickerCancel>Cancel</button>
              <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
            </mat-date-range-picker-actions>
          </mat-date-range-picker>
        </mat-form-field>
      </p>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div>
      <a mat-button routerLink="/circle">Quitter</a>
      <button mat-raised-button color="primary" [disabled]="!eventForm.valid" (click)="save()">Sauvegarder</button>
    </div>
  </mat-card-actions>

</mat-card>




