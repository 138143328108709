import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-cancellation',
  templateUrl: './event-cancellation.component.html',
  styleUrls: ['./event-cancellation.component.scss']
})
export class EventCancellationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
