
<mat-card>
  <mat-card-header>
    <mat-card-title>J'ai une nouvelle idée "cadeau" 🎁</mat-card-title>
  </mat-card-header>
  <mat-card-content>



    <form [formGroup]="wishForm">

<!--      <label id="example-radio-group-label">Pour</label>-->
<!--      <mat-radio-group-->
<!--        aria-labelledby="example-radio-group-label"-->
<!--        class="example-radio-group"-->
<!--        [(ngModel)]="suggestedFor">-->
<!--        <mat-radio-button [value]="'ME'">-->
<!--          Moi-->
<!--        </mat-radio-button>-->
<!--        <mat-radio-button [value]="'OTHER'">-->
<!--          Quelqu'un d'autre-->
<!--        </mat-radio-button>-->
<!--      </mat-radio-group>-->

      <mat-slide-toggle
        (toggleChange)="toggleSuggestion($event)"
        [checked]="isSuggestedForOther">
        Pour quelqu'un<sup>*</sup>  ?
      </mat-slide-toggle>

      <mat-form-field appearance="fill" *ngIf="isSuggestedForOther">
        <mat-label>Pour</mat-label>
        <input type="text" matInput formControlName="friend" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredFriends$ | async" [value]="option">
            {{option.username}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>


      <mat-form-field class="wish">
        <mat-label>J'ai répéré cette idée cadeaux:</mat-label>
        <textarea matInput placeholder="J'ai répéré cette idée cadeaux..." formControlName="content" rows="10" ></textarea>
      </mat-form-field>
    </form>

    <div *ngIf="isSuggestedForOther">*: Il ne verra pas ta suggestion!</div>
  </mat-card-content>
  <mat-card-actions align="end">
    <a mat-raised-button color="" routerLink="..">
      <mat-icon>return</mat-icon>
      <span>Retour</span>
    </a>
    <button mat-raised-button color="primary" aria-label="Ajouter" (click)="add()">Ajouter</button>
  </mat-card-actions>
</mat-card>

