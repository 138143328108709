import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icons-defs',
  templateUrl: './icons-defs.component.html',
  styleUrls: ['./icons-defs.component.scss']
})
export class IconsDefsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
