import { Component, OnInit } from '@angular/core';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
} from 'angular-calendar';
import {Observable, Subject} from "rxjs";
import {Store} from "@ngrx/store";
import {deleteEvent, loadCurrentEvents, nextEvents, previousEvents, selectAgendaDate, selectAgendaView} from "../store/actions/event.actions";
import {getAgendaDate, getAgendaView, getEvents} from "../store/reducers";
import {map, shareReplay} from "rxjs/operators";
import {AgendaView} from "../model/agenda";
import {DeleteEventDialogComponent} from "./delete-event-dialog/delete-event-dialog.component";
import {MatDialog} from "@angular/material/dialog";

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#C4ADAD',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {

  public viewDate$: Observable<Date> = this.store.select(getAgendaDate);
  public view$: Observable<AgendaView> = this.store.select(getAgendaView);
  public AgendaView = AgendaView;

  private editAction: CalendarEventAction = {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    };

  private deleteAction: CalendarEventAction =   {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        const dialogRef = this.dialog.open(DeleteEventDialogComponent, {
          data: {
            id: event.id
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.store.dispatch(deleteEvent({id: event.id}));
          }
        });

      },
    };

  public events$: Observable<CalendarEvent[]> = this.store.select(getEvents).pipe(
    map(events => {
      return events.map((event) => {
        const actions: CalendarEventAction[] = [];
        if (event.editable) {
          // actions.push(this.editAction);
          actions.push(this.deleteAction);
        }
        return {
          id: event.id,
          start: event.start,
          end: event.end,
          title: event.title,
          color: colors.red,
          actions: actions,
          allDay: true,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          draggable: true,
        } as CalendarEvent;
      });
  })
  );

  public refresh$: Subject<any> = new Subject();


  activeDayIsOpen: boolean = true;

  constructor(private store: Store,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.store.dispatch(loadCurrentEvents());
    this.events$.subscribe(() => {
      this.refresh$.next();
    });


  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.store.dispatch(selectAgendaDate({date}));
    // if (isSameMonth(date, this.viewDate)) {
    //   this.activeDayIsOpen = (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length > 0;
    // }
  }

  eventTimesChanged({
                      event,
                      newStart,
                      newEnd,
                    }: CalendarEventTimesChangedEvent): void {

    console.log("event changed", event, newStart, newEnd)

    // this.events = this.events$.map((iEvent) => {
    //   if (iEvent === event) {
    //     return {
    //       ...event,
    //       start: newStart,
    //       end: newEnd,
    //     };
    //   }
    //   return iEvent;
    // });
    // this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    console.log("handleEvent", action, event);
    //this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  public previous() {
    this.store.dispatch(previousEvents())
  }

  public next() {
    this.store.dispatch(nextEvents())
  }

  public today() {
    this.store.dispatch(loadCurrentEvents())
  }

  public viewMonth() {
    this.store.dispatch(selectAgendaView({view: AgendaView.MONTH}))
  }

  public viewWeek() {
    this.store.dispatch(selectAgendaView({view: AgendaView.WEEK}))
  }

  public viewDay() {
    this.store.dispatch(selectAgendaView({view: AgendaView.DAY}))
  }

}
//
// @Component({
//   selector: 'dialog-data-example-dialog',
//   templateUrl: 'dialog-data-example-dialog.html',
// })
// export class DialogDataExampleDialog {
//   constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
// }
