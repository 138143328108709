import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {MyErrorStateMatcher} from "../../shared/error-state-matcher";
import {UserService} from "../../services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'login-link',
  templateUrl: './login-link.component.html',
  styleUrls: ['./login-link.component.scss']
})
export class LoginLinkComponent implements OnInit {

  public emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  responseOk: boolean;
  responseNok: boolean;

  constructor(private userService: UserService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  public send() {
    this.responseOk=false;
    this.responseNok=false;

    this.userService.magiclink({email: this.emailFormControl.value}).subscribe(() => {
      this.responseOk = true;
      this.snackBar.openFromComponent(MagikLinkSentComponent, {
        duration: 5 * 1000,
      });

    },
      error => { this.responseNok = true;});
  }
}

@Component({
  selector: 'magik-link-send',
  template: `<div>
              <p>
                Tu recevras dans quelques instants un email avec un lien qui te premettras de rentrer!!! 🌟
              </p>
              <p>Si tu ne reçois rien, vérifie tes spams... 🔎</p>
            </div>`,
  styles: [
    `

  `,
  ],
})
export class MagikLinkSentComponent {}
