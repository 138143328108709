import {Component, Input, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Event} from "../../model/event";
import {Store} from "@ngrx/store";
import {addEvent} from "../../store/actions/event.actions";
import {Router} from "@angular/router";
import {Agenda} from "../../model/agenda";

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit {

  @Input()
  public event: Event;

  public eventForm = new FormGroup({
    // uid: new FormControl("", [Validators.required]),
    title: new FormControl("", [Validators.required]),
    //description: new FormControl("", [Validators.required]),
    // participants: new FormControl("", [Validators.minLength(1)]),
    start: new FormControl("", [Validators.required]),
    end: new FormControl("", [Validators.required])
  });

  constructor(private store: Store<Agenda>,
              private router: Router) { }

  ngOnInit(): void {
  }

  public save() {
    this.store.dispatch(addEvent({event: this.eventForm.getRawValue()}));
    this.router.navigate(['agenda']);
  }

  public delete() {

  }

}
