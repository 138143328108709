<mat-card>
  <mat-card-header>
    <mat-card-title>Mes idées "cadeau"</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div *ngFor="let wish of wishes$ | async ">
      <span *ngIf="wish.suggestedFor">Pour {{wish.suggestedFor.username}}:</span><span>{{wish.content}}</span> <mat-icon (click)="delete(wish)">delete</mat-icon>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <a mat-raised-button color="" routerLink="..">
      <span>Retour</span>
    </a>
    <a mat-raised-button color="primary" routerLink="../edit">
      <mat-icon>add</mat-icon>
      <span>Ajouter une idée</span></a>
  </mat-card-actions>
</mat-card>


