import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CreateMessage, Message, ReplyMessage} from "../model/message";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private httpClient: HttpClient) { }

  public get(circleId: string): Observable<Message[]> {
    return this.httpClient.get<Message[]>(`${environment.apiUri}/messages/${circleId}`);
  }

  public create(message: CreateMessage): Observable<Message> {
    return this.httpClient.post<Message>(`${environment.apiUri}/messages/${message.circleUid}`, message);
  }

  public reply(answer: ReplyMessage): Observable<Message> {
    return this.httpClient.post<Message>(`${environment.apiUri}/messages/${answer.messageId}/reply`, answer);
  }

  public delete(messageId: number): Observable<void> {
    return this.httpClient.delete<void>(`${environment.apiUri}/messages/${messageId}`);
  }
}
