import {Component, OnInit, ViewChild} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {AuthService} from "../shared/auth.service";
import {UserService} from "../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {loadCircles} from "../store/actions/circle.actions";
import {Store} from "@ngrx/store";
import {Circles} from "../model/circle";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {

  public hide = true;

  public error: string;

  public loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required])
  });

  constructor(private userService: UserService,
              private authService: AuthService,
              private store: Store<Circles>,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  public login(): void {
    const credential = this.loginForm.getRawValue();

    this.userService.authenticate(credential).subscribe((user) => {
        this.router.navigate(["/circle"]);
      },
      (err) => {
        if (err.status === 401) {
          this.error = "Mauvais Email/Mot de passe";
        } else {
          this.error = err.statusText;
        }
        console.error("Not logged in", err);
      })
  }

  public togglePassword($event) {
    $event.stopPropagation();
    this.hide = !this.hide;
  }

}
