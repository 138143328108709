import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {AuthService} from "../shared/auth.service";
import {FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {UserService} from "../services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit {

  // @ViewChild('passwordComponentWithValidation')
  // public passwordComponentWithValidation: MatPasswordStrengthComponent;

  //public oldPasswordFormControl = new FormControl('', [Validators.required]);
  public newPasswordFormControl = new FormControl('', [Validators.required]);


  @ViewChild('passwordNotUpdated', { read: TemplateRef })
  passwordNotUpdatedTemplate:TemplateRef<any>;

  @ViewChild('passwordUpdated', { read: TemplateRef })
  passwordUpdatedTemplate:TemplateRef<any>;


  constructor(private router: Router,
              private authService: AuthService,
              private userService: UserService,
              private snackBar: MatSnackBar) {}

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  public onStrengthChanged($event) {
    console.log("pouet", $event);
  }

  public updatePassword() {
    //ici on ne dispatch pas une action avec les passwords dans le store
    this.userService.password(this.newPasswordFormControl.value).subscribe(() => {
      console.info("OKOKO");
      this.snackBar.openFromTemplate(this.passwordUpdatedTemplate, {duration: 3000});
    },
        error => {
      this.snackBar.openFromTemplate(this.passwordNotUpdatedTemplate, {duration: 3000, panelClass: "error"});
      console.error("Password not updated", error);
    })
  }

  public cancel() {
    this.router.navigateByUrl("/circle");
  }

}
