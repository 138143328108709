import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {of} from "rxjs";
import {map, mergeMap, catchError, filter} from "rxjs/operators";
import {
  createMessage,
  createMessageFailure,
  createMessageSuccess, deleteMessage, deleteMessageFailure, deleteMessageSuccess,
  loadMessages,
  loadMessagesFailure,
  loadMessagesSuccess, replyMessage
} from "../actions/message.actions";
import {MessagesService} from "../../services/messages.service";
import {loadCirclesSuccess} from "../actions/circle.actions";


@Injectable()
// @ts-ignore
export class MessageEffects {

  loadMessages$ = createEffect(() => this.actions$.pipe(
    ofType(loadMessages),
    mergeMap((action) => this.messagesService.get(action.circleUid)
      .pipe(
        map((messages) => loadMessagesSuccess({messages})),
        catchError((error) => of(loadMessagesFailure({ error })))
      ))
    )
  );

  loadCirclesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loadCirclesSuccess),
    filter(action => action.currentCircle !== undefined),
    mergeMap((action) => this.messagesService.get(action.currentCircle.uid)
      .pipe(
        map((messages) => loadMessagesSuccess({messages})),
        catchError((error) => of(loadMessagesFailure({ error })))
      ))
    )
  );

  createMessage$ = createEffect(() => this.actions$.pipe(
    ofType(createMessage),
    mergeMap((action) => this.messagesService.create(action.message)
      .pipe(
        map((message)  => createMessageSuccess({message})),
        catchError((error) => of(createMessageFailure({ error: error.error })))
      ))
    )
  );

  replyMessage$ = createEffect(() => this.actions$.pipe(
    ofType(replyMessage),
    mergeMap((action) => this.messagesService.reply(action.message)
      .pipe(
        map((message)  => createMessageSuccess({message})),
        catchError((error) => of(createMessageFailure({ error: error.error })))
      ))
    )
  );

  deleteMessage$ = createEffect(() => this.actions$.pipe(
    ofType(deleteMessage),
    mergeMap((action) => this.messagesService.delete(action.message.id)
      .pipe(
        map((message)  => deleteMessageSuccess({messageId: action.message.id})),
        catchError((error) => of(deleteMessageFailure({ error: error.error })))
      ))
    )
  );

  constructor(
    private actions$: Actions,
    private messagesService: MessagesService
  ) {}
}
