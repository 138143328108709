<mat-card>
  <mat-card-header>
    <div mat-card-avatar class="logo"></div>
    <mat-card-title>Slota.fr</mat-card-title>
  </mat-card-header>
  <mat-card-actions align="end">
<!--    <a mat-button mat-raised-button color="primary" routerLink="register">Inscription</a>-->
    <a mat-button mat-raised-button color="primary" routerLink="login"><mat-icon>fingerprint</mat-icon>Login</a>
  </mat-card-actions>
</mat-card>

