import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Message} from "../model/message";

@Component({
  selector: 'message-reply-dialog',
  templateUrl: './message-reply-dialog.component.html',
  styleUrls: ['./message-reply-dialog.component.scss']
})
export class MessageReplyDialogComponent  {

  constructor(
    public dialogRef: MatDialogRef<MessageReplyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {message: Message, reply: string}) {}

  cancel(): void {
    this.dialogRef.close();
  }

}
