import {Component, OnInit} from "@angular/core";


@Component({
  selector: 'hello',
  templateUrl: './hello.component.html'
})
export class HelloComponent {

}
