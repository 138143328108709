<h1 mat-dialog-title>Répondre à {{data.message.from}}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <textarea [placeholder]="data.message.content" matInput [(ngModel)]="data.reply"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Annuler</button>
  <button [mat-dialog-close]="data.reply" mat-button cdkFocusInitial>Ok</button>
</div>
