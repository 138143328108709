<ng-container *ngIf="circle$ | async as circle">

  <div class="messages">

    <div class="flow">
      <ng-container *ngFor="let message of messages$ | async">
        <app-message-view class="message" [message]="message"></app-message-view>
        <mat-divider></mat-divider>
      </ng-container>
    </div>

    <div class="input">
      <message-form></message-form>
    </div>

  </div>

  <ng-template #aloneTemplate>
    <mat-card class="alone" >
      <mat-card-title>Seul au monde!!</mat-card-title>
      <mat-card-content>
        <p>Y'a personne dans cette discussion!! </p>
      </mat-card-content>
      <mat-card-actions *ngIf="circle?.editable">
        <a mat-raised-button routerLink="/circle/members"><mat-icon>person_add</mat-icon>Ajouter des participants</a>
      </mat-card-actions>
    </mat-card>
  </ng-template>

</ng-container>







