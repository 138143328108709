import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import {Wish} from "./wish.model";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Gift} from "./gift.model";

@Injectable({ providedIn: 'root' })
export class GiftService extends EntityCollectionServiceBase<Gift> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Gift', serviceElementsFactory);
  }

}
