import {timer} from "rxjs";

export abstract class Command {

  public execute(): void {
    // do nothing
  };
}

export class LongTaskCommand extends Command {

  private timer;

  constructor() {
    super();
    this.timer = Math.floor(Math.random() * 1000)
  }

  public execute(): void {
    // long task...
    while(this.timer-- > 0) {}
    console.log("cmdA");
  };
}
