import {createAction, props} from "@ngrx/store";
import {CreateMessage, Message, ReplyMessage} from "../../model/message";

export const loadMessages = createAction("LoadMessages", props<{ circleUid: string }>());
export const loadMessagesSuccess = createAction('LoadMessagesSuccess', props<{ messages: Message[] }>());
export const loadMessagesFailure = createAction( 'LoadMessageFailure', props<{ error: string }>());

export const createMessage = createAction("AddMessage", props<{ message: CreateMessage }>());
export const createMessageSuccess = createAction('CreateMessageSuccess', props<{ message: Message  }>());
export const createMessageFailure = createAction( 'CreateMessageFailure', props<{ error: string }>());

export const replyMessage = createAction("ReplyMessage", props<{ message: ReplyMessage }>());
export const replyMessageSuccess = createAction('ReplyMessageSuccess', props<{ message: Message  }>());
export const replyMessageFailure = createAction( 'ReplyMessageFailure', props<{ error: string }>());

export const deleteMessage = createAction("DeleteMessage", props<{ message: Message }>());
export const deleteMessageSuccess = createAction('DeleteMessageSuccess', props<{ messageId: number  }>());
export const deleteMessageFailure = createAction( 'DeleteMessageFailure', props<{ error: string }>());
