import {Event} from "./event";

export interface Agenda {
  date: Date;
  view: AgendaView;
  events: Event[]; // displayed events
}


export enum AgendaView {
  MONTH='month', WEEK='week', DAY='day'
}
