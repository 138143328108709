import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {Message, ReplyMessage} from "../model/message";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MessageActionsSheetComponent} from "./message-actions-sheet.component";
import {colorOf} from "../shared/hash-color";
import marked, { Renderer } from 'marked';
import * as highlightjs from 'highlight.js';
import {PicturesService} from "../services/pictures.service";
import {MatDialog} from "@angular/material/dialog";
import {MessageFileViewDialogComponent} from "./message-file-view-dialog.component";

@Component({
  selector: 'app-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['./message-view.component.scss']
})
export class MessageViewComponent implements OnInit {

  @Input()
  public message: Message;

  public html: string;
  public color;

  private md: any;

  constructor(private _bottomSheet: MatBottomSheet,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.color = colorOf(this.message.from);

    const renderer = new Renderer();
    renderer.code = MessageViewComponent.highlightCode;
    this.md = marked.setOptions({ renderer });
    this.html = this.md(this.message.content);
  }

  public pictureSrc(file: string): string {
    return `${PicturesService.url}/thumb/${file}`;
  }

  public openActionsSheet($event) {
    this._bottomSheet.open(MessageActionsSheetComponent, {
      data: { message : this.message },
    });
  }

  static highlightCode(code: string, language: string): string {
    if (!(language && highlightjs.getLanguage(language))) {
      // use 'markdown' as default language
      language = 'markdown';
    }

    const result = highlightjs.highlight(language, code).value;
    return `<code class="hljs ${language}">${result}</code>`;
  }

  public showFile($event, file:string) {
    $event.preventDefault();
    this.dialog.open(MessageFileViewDialogComponent, {
      width: '1000px',
      data: {
        file
      }
    });
  }

}
