import {Component, Inject} from "@angular/core";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {Message, MessagesFlow, ReplyMessage} from "../model/message";
import {Store} from "@ngrx/store";
import {deleteMessage, replyMessage} from "../store/actions/message.actions";
import {MatDialog} from "@angular/material/dialog";
import {MessageReplyDialogComponent} from "./message-reply-dialog.component";

@Component({
  selector: 'message-action-sheet',
  templateUrl: 'message-actions-sheet.component.html'
})
export class MessageActionsSheetComponent {

  constructor(private _bottomSheetRef: MatBottomSheetRef<MessageActionsSheetComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: {message: Message},
              private store: Store<MessagesFlow>,
              public dialog: MatDialog) {

  }

  delete(event: MouseEvent): void {
    this.store.dispatch(deleteMessage({message: this.data.message}));
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  reply(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
    const messageId = this.data.message.id;

    const dialogRef = this.dialog.open(MessageReplyDialogComponent, {
      width: '400px',
      data: {
        message: this.data.message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(replyMessage({ message: {
          content: result,
          messageId
      } as ReplyMessage}));
    });

  }

}
