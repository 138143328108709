import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, withLatestFrom} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {
  addEvent,
  createEventFailure,
  createEventSuccess,
  deleteEvent,
  deleteEventFailure,
  deleteEventSuccess,
  loadCurrentEvents,
  loadEventsFailure,
  loadEventsSuccess, nextEvents, previousEvents
} from "../actions/event.actions";
import {EventsService} from "../../services/events.service";
import {getAgendaDate, getAgendaView} from "../reducers";
import {Store} from "@ngrx/store";
import {AgendaView} from "../../model/agenda";
import { DateTime } from 'luxon';
import { Interval } from "date-fns";

@Injectable()
// @ts-ignore
export class EventEffects {

  createEvent$ = createEffect(() => this.actions$.pipe(
    ofType(addEvent),
    mergeMap((action) => this.eventsService.create(action.event)
      .pipe(
        map((event)  => createEventSuccess({event})),
        catchError((error) => of(createEventFailure({ error: error.error })))
      ))
    )
  );

  deleteEvent$ = createEffect(() => this.actions$.pipe(
    ofType(deleteEvent),
    mergeMap((action) => this.eventsService.delete(action.id)
      .pipe(
        map((response)  => deleteEventSuccess({id: response.id})),
        catchError((error) => of(deleteEventFailure({ error: error.error })))
      ))
    )
  );

  loadEvents$ = createEffect(() => this.actions$.pipe(
    ofType(loadCurrentEvents),
    withLatestFrom(this.store.select(getAgendaView)),
    map(([, currentView]) => {
      return new AgendaWindow(new Date(), currentView);
    } ),
    mergeMap((window: AgendaWindow) => this.getEvents$(window))
    )
  );

  previousEvents$ = createEffect(() => this.actions$.pipe(
    ofType(previousEvents),
    withLatestFrom(this.store.select(getAgendaDate), this.store.select(getAgendaView)),
    map(([, currentDate, currentView]) => {
      const window = new AgendaWindow(currentDate, currentView);
      return window.previous();
    } ),
    mergeMap((window: AgendaWindow) => this.getEvents$(window))
    )
  );

  nextEvents$ = createEffect(() => this.actions$.pipe(
    ofType(nextEvents),
    withLatestFrom(this.store.select(getAgendaDate), this.store.select(getAgendaView)),
    map(([, currentDate, currentView]) => {
      const window = new AgendaWindow(currentDate, currentView);
      return window.next();
    } ),
    mergeMap((window: AgendaWindow) => this.getEvents$(window))
    )
  );

  private getEvents$(window: AgendaWindow): Observable<any> {
    return this.eventsService.get(window.interval)
      .pipe(
        map(events => loadEventsSuccess({events, date: window.date, view: window.view})),
        catchError((error) => of(loadEventsFailure({ error })))
      );
  }

  constructor(
    private actions$: Actions,
    private eventsService: EventsService,
    private store: Store
  ) {}
}


class AgendaWindow {

  constructor(public readonly date: Date = new Date(), public readonly view: AgendaView = AgendaView.MONTH) {
  }

  public next(): AgendaWindow  {
    return this.plus(+1);
  }
  public previous(): AgendaWindow  {
    return this.plus(-1);
  }

  private plus(increment: number = 0): AgendaWindow {
    let datetime: DateTime = DateTime.fromJSDate(this.date);
    return new AgendaWindow(datetime.plus({[this.view + 's']: increment}).toJSDate(), this.view);
  }

  get interval(): Interval {
    const datetime: DateTime = DateTime.fromJSDate(this.date);

    //date-fns version
    // {start: startOfMonth(new Date()), end: addMonths(startOfMonth(new Date()), 1)}
    const start = datetime.startOf(this.view);
    const end = datetime.endOf(this.view);

    return {start, end};
  }

}
