import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../services/user.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public hide = true;
  public success = false;
  public error: string;

  public registerForm = new FormGroup({
    username: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required, Validators.minLength(8)]),
  });

  constructor(private userService: UserService,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  public register(): void {

    if (this.registerForm.valid) {

      const credential = this.registerForm.getRawValue();

      this.userService.register(credential).subscribe((user) => {
          // this.router.navigate(["login"]);
        this.success = true;
        },
        (err) => {
          this.error = err.statusText;
          console.error("Not registered", err);
        });
    }

  }

  public togglePassword($event: Event) {
    $event.stopPropagation();
    this.hide = !this.hide;
  }

}
