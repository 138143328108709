<mat-tab-group animationDuration="0ms">
  <mat-tab label="Message">

    <form [formGroup]="messageForm" (ngSubmit)="sendMessage()">
      <mat-form-field class="message">
        <mat-label>Envoyer un message...</mat-label>
        <textarea matInput placeholder="Envoyer un message..." formControlName="content" rows="3"></textarea>
      </mat-form-field>
      <button mat-fab color="accent" color="primary" aria-label="Envoyer">
        <mat-icon>send</mat-icon>
      </button>
    </form>

  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon [ngClass]="{'mat-primary': thumbnail}">add_photo_alternate</mat-icon>
    </ng-template>
      <div class="mat-form-field-appearance-standard">
        <span *ngIf="!thumbnail"><input type="file" accept="image/*" (change)="onFileChange($event)"/></span>
        <img *ngIf="thumbnail" src="{{thumbnail}}" alt="uploaded file"/>
        <div *ngIf="fileUploading"><mat-progress-spinner></mat-progress-spinner></div>
        <div *ngIf="error">{{error}}</div>
      </div>
  </mat-tab>
</mat-tab-group>


