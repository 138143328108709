import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {Wish} from "../wish.model";
import {WishService} from "../wish.service";
import {MatDialog} from "@angular/material/dialog";
import {GiftService} from "../gift.service";

@Component({
  selector: 'my-wishlist',
  templateUrl: './my-wishlist.component.html',
  styleUrls: ['./my-wishlist.component.scss']
})
export class MyWishlistComponent implements OnInit {

  loading$: Observable<boolean>;
  public wishes$: Observable<Wish[]>;

  constructor(private wishService: WishService,
              private giftService: GiftService,
              public dialog: MatDialog) {
    this.wishes$ = wishService.entities$;
    this.loading$ = wishService.loading$;
  }

  ngOnInit() {
    this.getWishes();
  }

  delete(wish: Wish) {
    const dialogRef = this.dialog.open(DeleteWishConfirmDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.wishService.delete(wish.id);
        this.giftService.clearCache();
      }
    });
  }

  getWishes() {
    this.wishService.getAll();
  }

}

@Component({
  selector: 'delete-wish-confirm-dialog',
  template: `
    <h2 mat-dialog-title>Confirmation de suppression</h2>
    <mat-dialog-content class="mat-typography">
      <p>Etes-vous sûr de supprimer cette idée cadeaux ?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Supprimer</button>
    </mat-dialog-actions>
  `
})
export class DeleteWishConfirmDialogComponent {}
