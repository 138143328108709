import {createAction, props} from "@ngrx/store";
import {User} from "../../model/user";

export const userLogin = createAction("UserLogin", props<{ email: string, password: string }>());
export const userLogged = createAction("UserLogged", props<{ user: User }>());
export const userRejected = createAction("UserRejected", props<{ error: string }>());

export const userRegister = createAction("UserRegister", props<{ email: string, password: string, username: string }>());

export const loadProfile = createAction("LoadProfile", props<{ userId: string }>());

export const userLogout = createAction("UserLogout");
export const userUnlogged = createAction("userUnlogged");
