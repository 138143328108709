import {ModuleWithProviders, NgModule} from "@angular/core";
import { CommonModule } from '@angular/common';
import {StoreModule} from "@ngrx/store";
import { entityConfig } from './wish-metadata';
import {EffectsModule} from "@ngrx/effects";
import {EntityDataModule} from "@ngrx/data";
import {WishlistComponent} from "./wishlist/wishlist.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import { WishEditComponent } from './wish-edit/wish-edit.component';
import {MatCardModule} from "@angular/material/card";
import {RouterModule} from "@angular/router";
import {WishlistRoutingModule} from "./wishlist-routing.module";
import {DeleteWishConfirmDialogComponent, MyWishlistComponent} from "./my-wishlist/my-wishlist.component";
import { GiftIdeasComponent } from './gift-ideas/gift-ideas.component';
import {MatDividerModule} from "@angular/material/divider";
import {WishListEffects} from "./store/wishlist.effects";
import {MatDialogModule} from "@angular/material/dialog";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatOptionModule} from "@angular/material/core";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatRadioModule} from "@angular/material/radio";

@NgModule({
  declarations: [
    WishlistComponent,
    WishEditComponent,
    MyWishlistComponent,
    GiftIdeasComponent,
    DeleteWishConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    WishlistRoutingModule,
    StoreModule.forFeature("wishlist", {}),
    EffectsModule.forFeature([WishListEffects]),
    EntityDataModule.forRoot(entityConfig),
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    RouterModule,
    MatDividerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatRadioModule
  ]
})
export class WishlistModule {
  public static forRoot(): ModuleWithProviders<WishlistModule> {
    return {
      ngModule: WishlistModule,
      providers: []
    };
  }
}
