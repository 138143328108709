import { Component, OnInit } from '@angular/core';
import {BehaviorSubject, combineLatest, ReplaySubject, Subject} from "rxjs";
import {tap} from "rxjs/operators";
import {Command, LongTaskCommand} from "./command";

@Component({
  selector: 'app-pouet',
  templateUrl: './pouet.component.html',
  styleUrls: ['./pouet.component.scss']
})
export class PouetComponent implements OnInit {

  private a$ = new Subject<string>();
  private b$ = new BehaviorSubject<string>("b");
  private c$ = new ReplaySubject<string>(2);

  private command$ = new Subject<Command>();

  constructor() {


  }

  ngOnInit(): void {

    this.c$.next("c1");
    this.c$.next("c2");
    this.c$.next("c3");


    this.a$.pipe(
      tap((x) => console.log("[a]", x))
    ).subscribe();

    this.b$.pipe(
      tap((x) => console.log("[b]", x))
    ).subscribe();

    this.c$.pipe(
      tap((x) => console.log("[c]", x))
    ).subscribe();


    combineLatest([this.a$, this.b$, this.c$]).pipe(
      tap((x) => console.log("[x]", x))
    ).subscribe();

  }

  public pouetA() {
    this.command$.next(new LongTaskCommand());
  }

  public pouetB() {
    this.b$.next("pouet")
  }

  public pouetC() {
    this.c$.next("pouet")
  }
}
