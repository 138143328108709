import {createReducer, on} from "@ngrx/store";
import {
  createMessageFailure,
  createMessageSuccess, deleteMessageSuccess, loadMessages,
  loadMessagesFailure,
  loadMessagesSuccess, replyMessageSuccess
} from "../actions/message.actions";
import {MessagesFlow} from "../../model/message";
import {createCircleSuccess, deleteCircleSuccess, loadCirclesSuccess, saveCircleSuccess, selectCircle} from "../actions/circle.actions";


export const initialState: MessagesFlow = {
  circle: undefined,
  messages: [],
  updatedAt: undefined,
  notification: undefined
};

export const messagesReducer = createReducer<MessagesFlow>(
  initialState,
  on(selectCircle, (state, action) => {
    const circle = action.circle;
    return {
      ...state,
      circle
    };
  }),
  on(loadCirclesSuccess, (state, action) => {
    const circle = action.currentCircle;
    return {
      ...state,
      circle
    };
  }),
  on(loadMessagesSuccess, (state, action) => {
    const messages = action.messages;
    return {
      ...state,
      messages
    };
  }),
  on(loadMessagesFailure, (state, action) => {
    return {
      ...state,
    };
  }),
  on(createMessageSuccess, (state, action) => {
    const messages = [action.message, ...state.messages];
    return {
      ...state,
      messages,
    };
  }),
  on(createMessageFailure, (state, action) => {
    return {
      ...state,
      notification: action.error
    };
  }),
  on(replyMessageSuccess, (state, action) => {
    const messages = [...state.messages.filter(message => message.id !== action.message.id), action.message]
      .sort((m1, m2) => m1.timestamp.getDate() - m2.timestamp.getDate());
    return {
      ...state,
    };
  }),
  on(saveCircleSuccess, (state, action) => {
    const circle = action.circle;
    return {
      ...state,
      circle,
    };
  }),
  on(createCircleSuccess, (state, action) => {
    const circle = action.circle;
    return {
      ...state,
      circle,
    };
  }),
  on(deleteCircleSuccess, (state, action) => {
    return {
      ...state,
      circle: undefined,
    };
  }),
  on(deleteMessageSuccess, (state, action) => {
    const messages = [...state.messages.filter(message => message.id !== action.messageId)];
    return {
      ...state,
      messages,
    };
  })
);

