import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class PicturesService {

  public static readonly url: string = `${environment.apiUri}/pictures`;

  constructor(private httpClient: HttpClient) { }

  public upload(formData: FormData): Observable<{filename: string}> {
    return this.httpClient.post<{filename: string}>(PicturesService.url, formData);
  }

}
