<!--suppress AngularUndefinedBinding -->
<mat-card class="message-card"  >
  <div class="avatar" (click)="openActionsSheet($event)" matCardAvatar [ngStyle]="{'background-color':color}">{{message.from.charAt(0).toUpperCase()}}</div>
  <div class="message">
    <div class="header">
      <span class="username">{{message.from}}</span> <span class="timestamp">{{message.createdAt | date:'dd/MM/yyyy H:mm' }}</span>
    </div>
    <div class="content" [innerHTML]= "this.message.content | linkify"></div>

    <ng-container *ngIf="message.files">
      <a *ngFor="let file of message.files" (click)="showFile($event, file)">
        <img  src="{{pictureSrc(file)}}" alt="picture"/>
      </a>
    </ng-container>

    <div *ngFor="let reply of message.replies" class="replies">
      <div class="header">
        <div>
          <span class="username"><strong>{{reply.from}}</strong></span>
          <span class="timestamp">{{reply.createdAt | date:'dd/MM/yyyy H:mm' }}</span>
        </div>
      </div>
      <div class="content" [innerHTML]= "reply.content | linkify"></div>
    </div>

  </div>
</mat-card>
