import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthService} from "./auth.service";

@Injectable()
export class TokenHeaderInterceptor implements HttpInterceptor {

  constructor(private readonly authService: AuthService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    const clonedRequest = req.clone({ setHeaders: { 'Authorization': `Bearer ${this.authService.getToken()}` } });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}
