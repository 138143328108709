import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IconsDefsComponent} from "./icons-defs/icons-defs.component";
import {IconComponent} from "./icon/icon.component";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    IconsDefsComponent,
    IconComponent
  ],
  exports: [
    IconsDefsComponent,
    IconComponent
  ]
})
export class IconsModule { }
