import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {of} from "rxjs";
import { map, mergeMap, catchError } from 'rxjs/operators';
import {
  addCircle,
  createCircleFailure,
  createCircleSuccess, deleteCircle, deleteCircleFailure, deleteCircleSuccess,
  loadCircles,
  loadCirclesFailure,
  loadCirclesSuccess,
  saveCircle, saveCircleFailure, saveCircleSuccess, viewedCircle, viewedCircleFailure, viewedCircleSuccess
} from "../actions/circle.actions";
import {CirclesService} from "../../services/circles.service";


@Injectable()
// @ts-ignore
export class CircleEffects {

  loadCircles$ = createEffect(() => this.actions$.pipe(
    ofType(loadCircles),
    mergeMap(() => this.circlesService.getAll()
      .pipe(
        map(results => loadCirclesSuccess({circles: results.circles, currentCircle: results.currentCircle})),
        catchError((error) => of(loadCirclesFailure({ error: error.headers.statusText })))
      ))
    )
  );

  createCircle$ = createEffect(() => this.actions$.pipe(
    ofType(addCircle),
    mergeMap((action) => this.circlesService.create(action.circle)
      .pipe(
        map((circle)  => createCircleSuccess({circle})),
        catchError((error) => of(createCircleFailure({ error: error.error })))
      ))
    )
  );

  saveCircle$ = createEffect(() => this.actions$.pipe(
    ofType(saveCircle),
    mergeMap((action) => this.circlesService.update(action.circle)
      .pipe(
        map((circle)  => saveCircleSuccess({circle})),
        catchError((error) => of(saveCircleFailure({ error: error.error })))
      ))
    )
  );

  deleteCircle$ = createEffect(() => this.actions$.pipe(
    ofType(deleteCircle),
    mergeMap((action) => this.circlesService.delete(action.uid)
      .pipe(
        map(({uid})  => deleteCircleSuccess({uid})),
        catchError((error) => of(deleteCircleFailure({ error: error.error })))
      ))
    )
  );

  onCircleViewed$ = createEffect(() => this.actions$.pipe(
    ofType(viewedCircle),
    mergeMap((action) => this.circlesService.ping(action.uid)
      .pipe(
        map(()  => viewedCircleSuccess()),
        catchError((error) => of(viewedCircleFailure({ error: error.error })))
      ))
    )
  );

  constructor(
    private actions$: Actions,
    private circlesService: CirclesService
  ) {}
}
