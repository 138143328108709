import {Component, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {filter, map, shareReplay, takeUntil, tap} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {Circle, Circles} from "../model/circle";
import {loadCircles, selectCircle} from "../store/actions/circle.actions";
import {getCircleNotifications, getCircles, getCurrentCircle, getMessages} from "../store/reducers";
import {AuthService} from "../shared/auth.service";
import {Router} from "@angular/router";
import {MAT_SNACK_BAR_DATA, MatSnackBar} from "@angular/material/snack-bar";
import {loadMessages} from "../store/actions/message.actions";
import {MatSidenav} from "@angular/material/sidenav";
import {userLogout} from "../store/actions/user.action";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"]
})
export class NavigationComponent implements OnInit, OnDestroy {

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  @ViewChild('drawer') sidenav: MatSidenav;

  public readonly circles$: Observable<Circle[]> = this.store.select(getCircles);
  public readonly currentCircle$: Observable<Circle> = this.store.select(getCurrentCircle);
  public readonly notification$: Observable<String> = this.store.select(getCircleNotifications);

  private durationInSeconds: number = 3;
  private _destroyed$ = new Subject();

  constructor(private breakpointObserver: BreakpointObserver,
              private store: Store<Circles>,
              public auth: AuthService,
              private router: Router,
              private snackBar: MatSnackBar) {

  }

  public ngOnInit(): void {
    if (this.auth.isLogged) {
      this.store.dispatch(loadCircles());
    }

    this.notification$.pipe(
      filter(notification => notification !== undefined),
      takeUntil(this._destroyed$)
    ).subscribe((_notification) => {
      this.snackBar.openFromComponent(NotificationComponent, {
        duration: this.durationInSeconds * 1000,
        data: _notification
      });
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public selectCircle(circle: Circle) {
    this.store.dispatch(selectCircle({circle: circle}));
    this.store.dispatch(loadMessages({circleUid: circle.uid}));
    this.router.navigate(["circle"]);
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.sidenav.close();
    }
  }

  public loginWithRedirect() {
    //this.auth.login();
    this.router.navigate(["login"]);
  }

  public logout() {
    console.log("log out...");
    this.store.dispatch(userLogout())
    this.router.navigateByUrl("/");
  }

}

@Component({
  selector: "notification",
  templateUrl: "notification.component.html",
  styles: [`
    .notif {
      color: hotpink;
    }
  `],
})
export class NotificationComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }
}

