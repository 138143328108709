import {createReducer, on} from "@ngrx/store";
import {
  createCircleFailure,
  createCircleSuccess, deleteCircleSuccess,
  loadCirclesFailure,
  loadCirclesSuccess,
  saveCircleFailure,
  saveCircleSuccess
} from "../actions/circle.actions";
import {Circles} from "../../model/circle";



export const initialState: Circles = {
  circles: [],
  notification: undefined
};

export const circlesReducer = createReducer<Circles>(
  initialState,
  on(loadCirclesSuccess, (state, action) => {
    const circles = action.circles;
    return {
      ...state,
      circles
    };
  }),
  on(loadCirclesFailure, (state, action) => {
    return {
      ...state,
      notification: action.error
    };
  }),
  on(createCircleSuccess, (state, action) => {
    const circles = [...state.circles, action.circle];
    return {
      ...state,
      circles,
    };
  }),
  on(createCircleFailure, (state, action) => {
    return {
      ...state,
      message: action.error
    };
  }),
  on(saveCircleSuccess, (state, action) => {
    const circles = [...state.circles.filter(circle => circle.uid !== action.circle.uid), action.circle]
      .sort((c1, c2) => c1.createdAt - c2.createdAt);
    return {
      ...state,
      circles,
    };
  }),
  on(deleteCircleSuccess, (state, action) => {
    const circles = [...state.circles.filter(circle => circle.uid !== action.uid)];
    return {
      ...state,
      circles,
    };
  })
);

