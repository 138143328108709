import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {of} from "rxjs";
import {WishService} from "../wish.service";
import {switchMap} from "rxjs/operators";
import {userLogout} from "../../store/actions/user.action";
import {wishlistCleared} from "./wishlist.actions";
import {GiftService} from "../gift.service";

@Injectable()
// @ts-ignore
export class WishListEffects {

  loadMyWishes$ = createEffect(() => this.actions$.pipe(
      ofType(userLogout),
      switchMap(() => {
        this.wishService.clearCache();
        this.giftService.clearCache();
        return of(wishlistCleared());
      })
    )
  );

  constructor(
    private actions$: Actions,
    private wishService: WishService,
    private giftService: GiftService
  ) {}
}
