import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-delete-event-dialog',
  templateUrl: './delete-event-dialog.component.html',
  styleUrls: ['./delete-event-dialog.component.scss']
})
export class DeleteEventDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {id: number},
              public dialogRef: MatDialogRef<DeleteEventDialogComponent>,) { }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public delete(id) {
    this.dialogRef.close();
  }
}
