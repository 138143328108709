import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from "@angular/platform-browser";
import {Injectable, NgModule} from "@angular/core";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatSliderModule} from "@angular/material/slider";
import {NavigationComponent, NotificationComponent} from "./navigation/navigation.component";
import {LayoutModule} from "@angular/cdk/layout";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatCardModule} from "@angular/material/card";
import {MatMenuModule} from "@angular/material/menu";
import {MatBadgeModule} from "@angular/material/badge";
import {MessageViewComponent} from "./message/message-view.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {AgendaComponent} from "./agenda/agenda.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MessagesFlowComponent} from "./message/messages-flow.component";
import {ErrorStateMatcher, MatNativeDateModule, ShowOnDirtyErrorStateMatcher} from "@angular/material/core";
import {MatTabsModule} from "@angular/material/tabs";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CommonModule, registerLocaleData} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {reducers, metaReducers} from "./store/reducers";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {EffectsModule} from "@ngrx/effects";
import {CircleEffects} from "./store/effects/circle.effects";
import {MessageEffects} from "./store/effects/message.effects";
import {environment as env} from "../environments/environment";
import {ProfileComponent} from "./user/profile.component";
import {AuthService} from "./shared/auth.service";
import {LoginComponent} from "./login/login.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RegisterComponent} from "./user/register.component";
import {AuthHttpInterceptor} from "./shared/auth-http-interceptor";
import {TokenHeaderInterceptor} from "./shared/token-header-interceptor";
import {HomeComponent} from "./home/home.component";
import {AuthGuard} from "./shared/auth.guard";
import {WtfComponent} from "./wtf/wtf.component";
import {NewCircleComponent} from "./circle/new-circle/new-circle.component";
import {NewCircleDialogComponent} from "./circle/new-circle/new-circle-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import localeFr from "@angular/common/locales/fr";
import {CircleViewComponent, DeleteCircleConfirmDialogComponent} from "./circle/circle-view/circle-view.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MessageActionsSheetComponent} from "./message/message-actions-sheet.component";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {EventViewComponent} from "./agenda/event-view/event-view.component";
import {EventEffects} from "./store/effects/event.effect";
import {MessageReplyDialogComponent} from "./message/message-reply-dialog.component";
import {CalendarModule, DateAdapter} from "angular-calendar";
import {adapterFactory} from "angular-calendar/date-adapters/date-fns";
import {InviteComponent} from "./user/invite.component";
import {JsonDateInterceptor} from "./shared/json-date-interceptor";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {DeleteEventDialogComponent} from "./agenda/delete-event-dialog/delete-event-dialog.component";
import {MessageFormComponent} from "./message/message-form.component";
import {SafeHtmlPipe} from "./shared/safe-html.pipe";
import {MessageFileViewDialogComponent} from "./message/message-file-view-dialog.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {StuffListComponent} from "./stuff/stuff-list/stuff-list.component";
import {HelloComponent} from "./pouet/hello.component";
import {PouetComponent} from "./pouet/pouet.component";
import {StuffEffects} from "./store/effects/stuff.effects";
import {CircleEditionViewComponent} from "./circle/circle-edition-view/circle-edition-view.component";
import {MatStepperModule} from "@angular/material/stepper";
import {WelcomeComponent} from "./user/welcome.component";
import {EventParticipationComponent} from "./agenda/event-participation/event-participation.component";
import {EventInvitationComponent} from "./agenda/event-invitation/event-invitation.component";
import {EventCancellationComponent} from "./agenda/event-cancellation/event-cancellation.component";
import {SoonComponent} from "./agenda/soon/soon.component";
import {LoginLinkComponent, MagikLinkSentComponent} from "./login/link/login-link.component";
import {IconsModule} from "./icons/icons.module";
import {MembersComponent} from "./circle/members/members.component";
import {WishlistModule} from "./wishlist/wishlist.module";
import {AdminGuard} from "./shared/admin.guard";
import {UserEffects} from "./store/effects/user.effects";
import {UtilsModule} from "./utils/utils.module";

registerLocaleData(localeFr, "fr");

// @Injectable()
// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = <any>{
//       // swipe: {enable: true},
//   };
//   options =  {touchAction:"pan-x"};
// }

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    DashboardComponent,
    MessageViewComponent,
    AgendaComponent,
    MessagesFlowComponent,
    ProfileComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    WtfComponent,
    NewCircleComponent,
    NewCircleDialogComponent,
    NotificationComponent,
    CircleViewComponent,
    MessageActionsSheetComponent,
    EventViewComponent,
    MessageReplyDialogComponent,
    InviteComponent,
    DeleteEventDialogComponent,
    MessageFormComponent,
    MessageFileViewDialogComponent,
    SafeHtmlPipe,
    StuffListComponent,
    PouetComponent,
    HelloComponent,
    CircleEditionViewComponent,
    WelcomeComponent,
    DeleteCircleConfirmDialogComponent,
    EventParticipationComponent,
    EventInvitationComponent,
    EventCancellationComponent,
    SoonComponent,
    LoginLinkComponent,
    MagikLinkSentComponent,
    MembersComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CommonModule,
        //HammerModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        MatNativeDateModule,
        MatSliderModule,
        MatBadgeModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatTabsModule,
        StoreModule.forRoot(reducers, {
            metaReducers
        }),
        //StoreModule.forRoot({app: appReducer, investmentAnalysis: investmentAnalysisReducer, bankingAccounts: bankingAccountsSearchReducer}),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
        EffectsModule.forRoot([CircleEffects, MessageEffects, EventEffects, StuffEffects, UserEffects]),
        ReactiveFormsModule,
        FormsModule,
        MatDialogModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatBottomSheetModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        IconsModule,
        WishlistModule.forRoot(),
        UtilsModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenHeaderInterceptor,
      multi: true,
    },
    {provide: HTTP_INTERCEPTORS, useClass: JsonDateInterceptor, multi: true},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    AuthService,
    AuthGuard,
    AdminGuard,
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    //{provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
