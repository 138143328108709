import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {WishService} from "../wish.service";
import {Router} from "@angular/router";
import {GiftService} from "../gift.service";
import {Friend} from "../../model/friend";
import {UserService} from "../../services/user.service";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {Wish} from "../wish.model";

@Component({
  selector: 'app-wish-edit',
  templateUrl: './wish-edit.component.html',
  styleUrls: ['./wish-edit.component.scss']
})
export class WishEditComponent implements OnInit {

  public wishForm = new FormGroup({
    content: new FormControl("", []),
    friend: new FormControl("", []),
  });

  friends: Friend[] = [];
  filteredFriends$: Observable<Friend[]>;

  isSuggestedForOther: boolean;

  constructor(private wishService: WishService,
              private giftService: GiftService,
              private userService: UserService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.userService.getFriends().subscribe((friends) => {
      this.friends = friends;
    })

    this.filteredFriends$ = this.wishForm.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._filter(name) : this.friends.slice())),
    );
  }

  displayFn(friend: Friend): string {
    return friend && friend.username ? friend.username : '';
  }

  public add() {
    let { content, friend}= this.wishForm.getRawValue();
    const wish = {content: content, suggestedFor: {id: friend.id}} as Wish;
    this.wishService.add(wish);
    this.router.navigate(['wishlist', 'mywishes']);
  }

  public toggleSuggestion($event) {
    this.isSuggestedForOther = !this.isSuggestedForOther;
  }

  // update(wish: Wish) {
  //   this.wishService.update(wish);
  // }

  private _filter(name: string): Friend[] {
    const filterValue = name.toLowerCase();

    return this.friends.filter(option => option.username.toLowerCase().includes(filterValue));
  }

}
