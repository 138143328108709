import {createEntityAdapter, EntityAdapter} from "@ngrx/entity";
import {Stuff} from "../../model/stuff";
import {StuffsState} from "../stuff-state";
import { stuffLoaded, stuffsLoaded} from "../actions/stuff.action";
import {createReducer, on} from "@ngrx/store";


export const stuffAdapter : EntityAdapter<Stuff> =
  createEntityAdapter<Stuff>({
    // sortComparer: sortBySeqNo
    // selectId: stuff => stuff['_id']
  });


export const initialStuffsState: StuffsState =
  stuffAdapter.getInitialState();

export const stuffsReducer = createReducer<StuffsState>(
  initialStuffsState,
  on(stuffLoaded, (state, action) => {
    return stuffAdapter.addOne(action.stuff, state);
  }),
  on(stuffsLoaded, (state, action) => {
    return stuffAdapter.addMany(action.stuffs, state);
  })


// case CourseActions.ADD_COURSE: {
//   return stuffAdapter.addOne(action.payload.course, state);
// }
//
// case CourseActions.UPSERT_COURSE: {
//   return stuffAdapter.upsertOne(action.payload.course, state);
// }
//
// case CourseActions.ADD_COURSES: {
//   return stuffAdapter.addMany(action.payload.courses, state);
// }
//
// case CourseActions.UPSERT_COURSES: {
//   return stuffAdapter.upsertMany(action.payload.courses, state);
// }
//
// case CourseActions.UPDATE_COURSE: {
//   return stuffAdapter.updateOne(action.payload.course, state);
// }
//
// case CourseActions.UPDATE_COURSES: {
//   return stuffAdapter.updateMany(action.payload.courses, state);
// }
//
// case CourseActions.DELETE_COURSE: {
//   return stuffAdapter.removeOne(action.payload.id, state);
// }
//
// case CourseActions.DELETE_COURSES: {
//   return stuffAdapter.removeMany(action.payload.ids, state);
// }
//
// case CourseActions.LOAD_COURSES: {
//   return stuffAdapter.addAll(action.payload.courses, state);
// }
//
// case CourseActions.CLEAR_COURSES: {
//   return stuffAdapter.removeAll(state);
// }

);

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal

} = stuffAdapter.getSelectors();
