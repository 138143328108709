import {Component, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {Gift} from "../gift.model";
import {GiftService} from "../gift.service";
import {map} from "rxjs/operators";

@Component({
  selector: "gift-ideas",
  templateUrl: "./gift-ideas.component.html",
  styleUrls: ["./gift-ideas.component.scss"]
})
export class GiftIdeasComponent implements OnInit {

  loading$: Observable<boolean>;
  public giftIdeas$: Observable<Gift[]>;

  public giftIdeasByUser$: Observable<{ [key: string]: Gift[] }>;
  public userGiftIdeas$: Observable<Array<{ username: string, gifts: Gift[] }>>;

  constructor(private giftService: GiftService) {
    this.giftIdeas$ = giftService.entities$;
    this.loading$ = giftService.loading$;

    this.giftIdeasByUser$ = this.giftIdeas$.pipe(
      map(gifts => groupBy(gifts, "username"))
    );
    this.userGiftIdeas$ = this.giftIdeasByUser$.pipe(
      map(giftsByUser => {
        return Object.entries(giftsByUser).map(([username, gifts]) => {
          return {
            username,
            gifts
          };
        })
      })
    );
  }

  ngOnInit() {
    this.getGiftIdeas();
  }

  getGiftIdeas() {
    this.giftService.getAll().pipe(
      map(gifts => groupBy(gifts, "user"))
      //groupBy(gift => gift.user),
      //mergeMap(group => group.pipe(toArray()))
      //mergeMap(group => zip(of(group.key), group.pipe(toArray())))
    );
  }

}

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
