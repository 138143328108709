<mat-card>
  <mat-card-title>Login</mat-card-title>
  <form [formGroup]="loginForm">
    <mat-card-content>
      <p class="mat-error" *ngIf="error">{{error}}</p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Enter your email</mat-label>
          <input matInput formControlName="email" required>
          <mat-error *ngIf="loginForm.controls.email.invalid">Email required</mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Enter your password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" >
          <button mat-icon-button matSuffix (click)="togglePassword($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" tabindex="-1">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid" (click)="login()" >Log In</button>
    </mat-card-actions>
  </form>
  <mat-card-footer>
      <a mat-button routerLink="/forgotten">Mot de passe oublié</a>
  </mat-card-footer>


</mat-card>

