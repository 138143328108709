
<div *ngFor="let giftIdeas of userGiftIdeas$ | async ">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{giftIdeas.username}}</mat-card-title>
      <mat-card-subtitle>souhaite:</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ul>
        <li *ngFor="let ideas of giftIdeas.gifts">
          <span>{{ideas.content}}</span> <span class="suggestedBy" *ngIf="ideas.suggestedBy">(Suggestion de {{ideas.suggestedBy}})</span>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>
</div>



