import {createAction, props} from "@ngrx/store";
import {Circle, CreateCircle, UpdateCircle} from "../../model/circle";

export const selectCircle = createAction("SelectCircle", props<{ circle: Circle }>());

export const loadCircles = createAction("LoadCircles");
export const loadCirclesSuccess = createAction('LoadCirclesSuccess', props<{ circles: Circle[], currentCircle: Circle }>());
export const loadCirclesFailure = createAction( 'LoadCirclesFailure', props<{ error: string }>());

export const addCircle = createAction("AddCircle", props<{ circle: CreateCircle }>());
export const createCircleSuccess = createAction('CreateCircleSuccess', props<{ circle: Circle  }>());
export const createCircleFailure = createAction( 'CreateCircleFailure', props<{ error: string }>());

export const saveCircle = createAction("SaveCircle", props<{ circle: UpdateCircle }>());
export const saveCircleSuccess = createAction('SaveCircleSuccess', props<{ circle: Circle  }>());
export const saveCircleFailure = createAction( 'SaveCircleFailure', props<{ error: string }>());

export const deleteCircle = createAction("DeleteCircle", props<{ uid: string }>());
export const deleteCircleSuccess = createAction('DeleteCircleSuccess', props<{ uid: string }>());
export const deleteCircleFailure = createAction( 'DeleteCircleFailure', props<{ error: string }>());

export const viewedCircle = createAction("ViewedCircle", props<{ uid: string }>());
export const viewedCircleSuccess = createAction("ViewedCircleSuccess");
export const viewedCircleFailure = createAction("ViewedCircleFailure", props<{ error: string }>());
