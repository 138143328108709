<mat-card>
  <mat-card-header>
    <mat-card-title>Idées "cadeau"</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <gift-ideas></gift-ideas>

  </mat-card-content>
  <mat-card-actions align="end">
    <a mat-raised-button color="" routerLink="mywishes">
      <mat-icon>lightbulb</mat-icon>
      <span>Mes idées</span>
    </a>
    <a mat-raised-button color="primary" routerLink="edit">
      <mat-icon>add</mat-icon>
      <span>Ajouter une idée</span>
    </a>
  </mat-card-actions>
</mat-card>


