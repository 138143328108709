<mat-card>
  <mat-card-header>
    <mat-card-title>Mise à jour du mot de passe</mat-card-title>
  </mat-card-header>
  <mat-card-content>

<!--    <mat-form-field appearance="outline" style="width: 100%">-->
<!--      <mat-label>Ton mot de passe actuel</mat-label>-->
<!--&lt;!&ndash;      <mat-pass-toggle-visibility #toggleOld matSuffix></mat-pass-toggle-visibility>&ndash;&gt;-->
<!--      <input-->
<!--        matInput-->
<!--        #previousPassword-->
<!--        type="password"-->
<!--        required-->
<!--        [formControl]="oldPasswordFormControl"-->
<!--        placeholder="Old Password"-->
<!--        tabindex="0"-->
<!--      />-->
<!--    </mat-form-field>-->


    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Ton nouveau mot de passe hyper sécurisé (au moins 6 caractères!!)</mat-label>
<!--      <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>-->
      <input
        matInput
        #passwordWithValidation
        type="password"
        required
        [formControl]="newPasswordFormControl"
        placeholder="New Password"
        tabindex="1"
      />
      <mat-error
        *ngIf="newPasswordFormControl.hasError('required')"
      >
        Password is required
      </mat-error>
      <mat-error
        *ngIf="newPasswordFormControl.hasError('pattern')"
      >
        Password is not valid
      </mat-error>
    </mat-form-field>
<!--    <mat-password-strength-->
<!--      #passwordComponentWithValidation-->
<!--      (onStrengthChanged)="onStrengthChanged($event)"-->
<!--      [password]="passwordWithValidation.value"-->
<!--      [enableSpecialCharRule]="false"-->
<!--      [enableUpperCaseLetterRule]="false"-->
<!--    >-->
<!--    </mat-password-strength>-->
<!--    <mat-password-strength-info-->
<!--      [passwordComponent]="passwordComponentWithValidation"-->
<!--      [lowerCaseCriteriaMsg]="'Au moins une lettre minuscule est requise'"-->
<!--      [upperCaseCriteriaMsg]="'Au moins une lettre majuscule est requise'"-->
<!--      [digitsCriteriaMsg]="'Au moins un chiffre est requis'"-->
<!--      [specialCharsCriteriaMsg]="'Au moins un caractère spécial est requis'"-->
<!--      [enableScoreInfo]="false"-->
<!--    >-->
<!--    </mat-password-strength-info>-->

  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button (click)="cancel()">Annuler</button>
    <button mat-raised-button color="primary" tabindex="3" (click)="updatePassword()" [disabled]="!newPasswordFormControl.valid">Modifier mot de passe</button>
  </mat-card-actions>
</mat-card>

<ng-template #passwordNotUpdated>
  <span class="error">Ton mot de passe n'a pas pu être mis à jour! 😞</span>
</ng-template>

<ng-template #passwordUpdated>
  <span class="success">Ton mot de passea été mis à jour! 🤩</span>
</ng-template>
