<mat-card>
  <form class="example-form">
    <mat-card-title>Invitation</mat-card-title>
    <mat-card-content>

        <mat-form-field class="example-full-width">
          <mat-label>Email</mat-label>
          <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher">
          <mat-hint>Errors appear instantly!</mat-hint>
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Message</mat-label>
          <textarea type="email" matInput [formControl]="messageFormControl"
                    placeholder="Un petit message d'accueil peut être?"></textarea>
          <mat-error *ngIf="messageFormControl.hasError('maxLength')">
            Trop long ce message!! (2000 char max.)
          </mat-error>
        </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-raised-button (click)="cancel()">Annuler</button>
      <button mat-raised-button color="primary" tabindex="3" (click)="invite()" [disabled]="!emailFormControl.valid || !messageFormControl.valid">Inviter</button>
    </mat-card-actions>
  </form>
</mat-card>
