import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import {Wish} from "./wish.model";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({ providedIn: 'root' })
export class WishService extends EntityCollectionServiceBase<Wish> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory,
              private httpClient: HttpClient) {
    super('Wish', serviceElementsFactory);
  }

  public getMyWishes(): Observable<Wish[]> {
    return this.httpClient.get<Wish[]>(`${environment.apiUri}/wishes/mine`);
  }

  public getOtherWishes(): Observable<Wish[]> {
    return this.httpClient.get<Wish[]>(`${environment.apiUri}/wishes/other`);
  }
}
