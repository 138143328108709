<mat-card *ngIf="!success">
  <form [formGroup]="registerForm">
  <mat-card-title>Inscription</mat-card-title>
    <mat-card-content>
      <p class="mat-error" *ngIf="error">{{error}}</p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>C'est qui?</mat-label>
          <input matInput formControlName="username" required>
          <mat-error *ngIf="registerForm.controls.username.invalid">Il me faut un petit nom d'utilisateur!</mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Ton email?</mat-label>
          <input matInput placeholder="pat@example.com" formControlName="email" required>
          <mat-error *ngIf="registerForm.controls.email.invalid">Un email valide est requis!</mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Un mot de passe hyper sécurisé</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
          <button mat-icon-button matSuffix (click)="togglePassword($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="registerForm.controls.password.invalid">Un mot de passe de 8 caractères minimum est requis!!!</mat-error>
        </mat-form-field>
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" type="submit" [disabled]="!registerForm.valid" (click)="register()">Je m'inscris</button>
    </mat-card-actions>
  </form>
</mat-card>

<mat-card *ngIf="success" class="success">
  <mat-card-title>Inscription Réussi</mat-card-title>
  <mat-card-content>
    <mat-icon>verified</mat-icon>
    <p>Tu peux désormais te logguer!!</p>
  </mat-card-content>
  <mat-card-actions>
    <a mat-button routerLink="/login">Log In</a>
  </mat-card-actions>
</mat-card>

