import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Circle, CreateCircle, UpdateCircle} from "../model/circle";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CirclesService {

  constructor(private httpClient: HttpClient) { }

  public getAll(): Observable<{circles: Circle[], currentCircle: Circle}> {
    return this.httpClient.get<{circles: Circle[], currentCircle: Circle}>(`${environment.apiUri}/circles`);
  }

  public get(uid: string): Observable<Circle> {
    return this.httpClient.get<Circle>(`${environment.apiUri}/circles/${uid}`);
  }

  public create(circle: CreateCircle): Observable<Circle> {
    return this.httpClient.post<Circle>(`${environment.apiUri}/circles`, circle);
  }

  public update(circle: UpdateCircle): Observable<Circle> {
    return this.httpClient.put<Circle>(`${environment.apiUri}/circles`, circle);
  }

  public ping(uid: string): Observable<void> {
    return this.httpClient.get<void>(`${environment.apiUri}/circles/ping/${uid}`);
  }

  public delete(uid: string): Observable<{uid: string}> {
    return this.httpClient.delete<{uid: string}>(`${environment.apiUri}/circles/${uid}`);
  }
}
