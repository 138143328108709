import {RouterModule, Routes} from "@angular/router";
import {WishlistComponent} from "./wishlist/wishlist.component";
import {WishEditComponent} from "./wish-edit/wish-edit.component";
import {NgModule} from "@angular/core";
import {MyWishlistComponent} from "./my-wishlist/my-wishlist.component";

const routes: Routes = [
  { path: '', pathMatch: 'full', component: WishlistComponent },
  { path: 'mywishes', component: MyWishlistComponent },
  { path: 'edit', component: WishEditComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WishlistRoutingModule {}
