import { Component, OnInit } from '@angular/core';
import {AuthService} from "../shared/auth.service";
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from "@angular/router";
import {UserService} from "../services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(route: ActivatedRoute,
              router: Router,
              userService: UserService,
              snackBar: MatSnackBar) {
    if (route.snapshot.queryParams.welcome) {
        userService.authenticateWithLaisserPasser(route.snapshot.queryParams.welcome).subscribe((user) => {
          router.navigateByUrl('/user/profile');
        },
          err => {
            snackBar.open("Refusé!!");
          });
    } else {
      router.navigateByUrl('/circle');
    }

  }

  ngOnInit(): void {
  }

}
