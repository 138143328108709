import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {MessagesFlowComponent} from "./message/messages-flow.component";
import {AgendaComponent} from "./agenda/agenda.component";
import {ProfileComponent} from "./user/profile.component";
import {LoginComponent} from "./login/login.component";
import {HomeComponent} from "./home/home.component";
import {AuthGuard} from "./shared/auth.guard";
import {WtfComponent} from "./wtf/wtf.component";
import {EventViewComponent} from "./agenda/event-view/event-view.component";
import {StuffListComponent} from "./stuff/stuff-list/stuff-list.component";
import {WelcomeComponent} from "./user/welcome.component";
import {CircleViewComponent} from "./circle/circle-view/circle-view.component";
import {InviteComponent} from "./user/invite.component";
import {NavigationComponent} from "./navigation/navigation.component";
import {LoginLinkComponent} from "./login/link/login-link.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {RegisterComponent} from "./user/register.component";
import {AdminGuard} from "./shared/admin.guard";

const routes: Routes = [

  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "forgotten",
    component: LoginLinkComponent
  },
  {
    path: "register",
    component: RegisterComponent
  },
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "",
    component: NavigationComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "welcome",
        component: WelcomeComponent
      },
      {
        path: "circle",
        component: MessagesFlowComponent
      },
      {
        path: "messages",
        component: MessagesFlowComponent
      },
      {
        path: "circle/edit",
        component: CircleViewComponent, // CircleEditionViewComponent
      },
      {
        path: "agenda",
        component: AgendaComponent,
      },
      {
        path: "agenda/event",
        component: EventViewComponent,
      },
      {
        path: "user/profile",
        component: ProfileComponent,
      },
      {
        path: "user/invite",
        component: InviteComponent,
      },
      {
        path: "stuff",
        component: StuffListComponent,
      },
      {
        path: "dashboard",
        component: DashboardComponent
      },
      {
        path: 'wishlist',
        loadChildren: () => import('./wishlist/wishlist.module').then(m => m.WishlistModule)
      }
    ]
  },


  {
    path: 'admin',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },

  {
    path: "wtf",
    component: WtfComponent,
  },
  {
    path: "**",
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: "legacy"})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
