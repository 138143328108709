import {Component, OnDestroy, OnInit} from "@angular/core";
import {Message, MessagesFlow} from "../model/message";
import {Observable, Subject} from "rxjs";
import {Store} from "@ngrx/store";
import {getCurrentCircle, getMessages} from "../store/reducers";
import {Circle} from "../model/circle";
import {debounceTime, filter, map, takeUntil, tap} from "rxjs/operators";
import {viewedCircle} from "../store/actions/circle.actions";

@Component({
  selector: 'app-messages-flow',
  templateUrl: './messages-flow.component.html',
  styleUrls: ['./messages-flow.component.scss']
})
export class MessagesFlowComponent implements OnInit, OnDestroy {

  public messages$: Observable<Message[]> = this.store.select(getMessages);
  public circle$: Observable<Circle> = this.store.select(getCurrentCircle);

  public alone$: Observable<boolean> = this.circle$.pipe(
    filter(circle => circle !== undefined),
    map(circle => circle.membersOnly && circle.members.length <= 1)
  );

  private destroy$ = new Subject();

  constructor(private store: Store<MessagesFlow>) { }

  ngOnInit(): void {
    this.circle$.pipe(
      debounceTime(3000),
      takeUntil(this.destroy$)
    ).subscribe(circle => {
      this.store.dispatch(viewedCircle({uid: circle.uid} ))
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
