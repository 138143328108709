import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly KEY: string = "__SECURE-C-QUI";

  constructor() { }

  public getToken(): string {
    return document.cookie?.split('; ')
      .find(row => row.startsWith(`${this.KEY}=`))?.split('=')[1];
  }

  get isLogged(): boolean {
    return this.getToken() !== null;
  }

  public logout(): void {
    document.cookie = `${this.KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  }
}
