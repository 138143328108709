import {createReducer, on} from "@ngrx/store";
import {deleteEventSuccess, loadEventsSuccess, selectAgendaView, selectAgendaDate} from "../actions/event.actions";
import {Agenda, AgendaView} from "../../model/agenda";


export const initialState: Agenda = {
  date: new Date(),
  view: AgendaView.MONTH,
  events: []
};

export const eventsReducer = createReducer<Agenda>(
  initialState,
  on(loadEventsSuccess, (state, action) => {
    const events = action.events;
    const date = action.date;
    const view = action.view;
    return {
      ...state,
      events,
      date,
      view
    };
  }),
  on(deleteEventSuccess, (state, action) => {
    const events = state.events.filter(event => event.id !== action.id);
    return {
      ...state,
      events
    };
  }),
  on(selectAgendaDate, (state, action) => {
    const date = action.date;
    return {
      ...state,
      date
    };
  }),
  on(selectAgendaView, (state, action) => {
    const view = action.view;
    return {
      ...state,
      view
    };
  })
);
