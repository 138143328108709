import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Stuff} from "../model/stuff";

@Injectable({
  providedIn: 'root'
})
export class StuffsService {

  constructor(private httpClient: HttpClient) { }

  public getAll(): Observable<Stuff[]> {
    return this.httpClient.get<Stuff[]>(`${environment.apiUri}/stuffs`);
  }

  public get(stuffId: string): Observable<Stuff> {
    return this.httpClient.get<Stuff>(`${environment.apiUri}/stuffs/${stuffId}`);
  }

  // public create(message: CreateMessage): Observable<Message> {
  //   return this.httpClient.post<Message>(`${environment.apiUri}/messages/${message.circleUid}`, message);
  // }

}
