<h1 mat-dialog-title>Nouvelle Discussion</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Sujet</mat-label>
    <input matInput [(ngModel)]="data.name">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Annuler</button>
  <button [mat-dialog-close]="data.name" mat-button cdkFocusInitial>Ok</button>
</div>
