import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Event, CreateEvent} from "../model/event";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private httpClient: HttpClient) { }

  public get(interval: Interval): Observable<Event[]> {
    let params = new HttpParams();
    params = params.append('start', new Date(interval.start).toISOString());
    params = params.append('end', new Date(interval.end).toISOString());
    return this.httpClient.get<Event[]>(`${environment.apiUri}/events`, {params});
  }

  public create(event: CreateEvent): Observable<Event> {
    return this.httpClient.post<Event>(`${environment.apiUri}/events`, event);
  }

  public delete(eventId: number | string): Observable<{id: string}> {
    return this.httpClient.delete<{id: string}>(`${environment.apiUri}/events/${eventId}`);
  }

}
