import { EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
  Wish: {},
  Gift: {},
};

const pluralNames = { Wish: 'Wishes' };

export const entityConfig = {
  entityMetadata,
  pluralNames
};
