import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  addCircle,
  createCircleFailure,
  createCircleSuccess, deleteCircle, deleteCircleFailure, deleteCircleSuccess,
  loadCircles,
  loadCirclesFailure,
  loadCirclesSuccess,
  saveCircle, saveCircleFailure, saveCircleSuccess, viewedCircle, viewedCircleFailure, viewedCircleSuccess
} from "../actions/circle.actions";
import {catchError, map, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {loadStuffs, stuffsFailure, stuffsLoaded} from "../actions/stuff.action";
import {StuffsService} from "../../services/stuff.services";

@Injectable()
// @ts-ignore
export class StuffEffects {

  loadStuffs$ = createEffect(() => this.actions$.pipe(
    ofType(loadStuffs),
    mergeMap(() => this.stuffsService.getAll()
      .pipe(
        map(stuffs => stuffsLoaded({stuffs})),
        catchError((error) => of(stuffsFailure({ error })))
      ))
    )
  );

  constructor(
    private actions$: Actions,
    private stuffsService: StuffsService
  ) {}
}
