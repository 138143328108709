import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Circle} from "../../model/circle";
import {Store} from "@ngrx/store";
import {MessagesFlow} from "../../model/message";
import {combineLatest, Observable, Subject} from "rxjs";
import {getCurrentCircle} from "../../store/reducers";
import {map, takeUntil} from "rxjs/operators";
import {User} from "../../model/user";
import {UserService} from "../../services/user.service";
import {Router} from "@angular/router";
import {deleteCircle, saveCircle} from "../../store/actions/circle.actions";
import {MatSelectionList} from "@angular/material/list";
import {MatDialog} from "@angular/material/dialog";



@Component({
  selector: 'circle-view',
  templateUrl: './circle-view.component.html',
  styleUrls: ['./circle-view.component.scss']
})
export class CircleViewComponent implements OnInit, OnDestroy {

  @ViewChild('members')
  public membersList: MatSelectionList;

  public circleForm = new FormGroup({
    uid: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    members: new FormControl("", [Validators.minLength(1)]),
    membersOnly: new FormControl("")
  });

  public circle$: Observable<Circle> = this.store.select(getCurrentCircle);

  public readonly users$: Observable<User[]>;

  public members$: Observable<User[]>;

  private destroy$ = new Subject();

  public error: string;

  constructor(private router: Router,
              private store: Store<MessagesFlow>,
              private userService: UserService,
              public dialog: MatDialog) {
    this.users$ = this.userService.getAll();
  }

  ngOnInit(): void {
    this.circle$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(circle => {
      if (circle === undefined) {
        this.router.navigate([''])
      } else {
        this.circleForm.setValue({
          uid: circle.uid,
          name: circle.name,
          members: circle.members || [],
          membersOnly: circle.membersOnly || false
        });
      }
    });

    this.members$ = combineLatest([this.circle$, this.users$]).pipe(
      map(([circle, users]) => {
        return users.filter(user => circle.members.findIndex(memberId => memberId === user.id) >= 0)
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public save() {
    this.store.dispatch(saveCircle({circle: this.circleForm.getRawValue()}));
    this.router.navigate(['circle']);
  }

  public delete() {
    const dialogRef = this.dialog.open(DeleteCircleConfirmDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result === true) {
        this.store.dispatch(deleteCircle({uid: this.circleForm.get('uid').value}));
        this.router.navigate(['circle']);
      }
    });

  }

}


@Component({
  selector: 'delete-circle-confirm-dialog',
  templateUrl: 'delete-circle-confirm-dialog.component.html',
})
export class DeleteCircleConfirmDialogComponent {}
