import {createAction, props} from "@ngrx/store";
import {CreateEvent, Event} from "../../model/event";
import {AgendaView} from "../../model/agenda";

export const selectAgendaDate = createAction("SelectDate", props<{ date: Date }>());
export const selectAgendaView = createAction("SelectView", props<{ view: AgendaView }>());

export const addEvent = createAction("AddEvent", props<{ event: CreateEvent }>());
export const selectEvent = createAction("SelectEvent", props<{ event: Event }>());

export const loadCurrentEvents = createAction("LoadCurrentEvents");
export const previousEvents = createAction("PreviousEvents");
export const nextEvents = createAction("NextEvents");

export const loadEventsSuccess = createAction('LoadEventsSuccess', props<{ events: Event[], date: Date; view: AgendaView }>());
export const loadEventsFailure = createAction( 'LoadEventsFailure', props<{ error: string }>());

export const createEventSuccess = createAction('CreateEventSuccess', props<{ event: Event  }>());
export const createEventFailure = createAction( 'CreateEventFailure', props<{ error: string }>());

//export const saveEvent = createAction("SaveEvent", props<{ event: UpdateEvent }>());
//export const saveEventSuccess = createAction('SaveEventSuccess', props<{ event: Event  }>());
//export const saveEventFailure = createAction( 'SaveEventFailure', props<{ error: string }>());

export const deleteEvent = createAction("DeleteEvent", props<{ id: number | string }>());
export const deleteEventSuccess = createAction('DeleteEventSuccess', props<{ id: string }>());
export const deleteEventFailure = createAction( 'DeleteEventFailure', props<{ error: string }>());
