import {createFeatureSelector, createSelector} from "@ngrx/store";
import {StuffsState} from "../stuff-state";

import * as fromStuff from '../reducers/stuff.reducers';

const selectStuffState = createFeatureSelector<StuffsState>("stuff");

export const selectAllStuffs = createSelector(
  selectStuffState,
  fromStuff.selectAll
);
