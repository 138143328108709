<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
<!--    <mat-toolbar><em class="fas fa-ankh"></em></mat-toolbar>-->
    <mat-nav-list class="home">
        <a mat-list-item routerLink="welcome" routerLinkActive="active"><span><mat-icon>home</mat-icon></span><span>Home</span></a>
        <mat-divider></mat-divider>
    </mat-nav-list>
    <mat-nav-list *ngIf="auth.isLogged">
      <mat-toolbar>Discussions</mat-toolbar>
      <a *ngFor="let circle of circles$ | async" mat-list-item (click)="selectCircle(circle)">
        <span matBadge="{{circle.pending}}" matBadgeOverlap="false">{{circle.name}}</span>
      </a>
      <new-circle></new-circle>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-nav-list class="tools" *ngIf="auth.isLogged">
<!--      <a mat-list-item routerLink="people" routerLinkActive="active"><mat-icon>face</mat-icon><span>People</span></a>-->
      <a mat-list-item routerLink="agenda" routerLinkActive="active"><mat-icon>calendar_today</mat-icon><span>Agenda</span></a>
<!--      <a mat-list-item routerLink="picture" routerLinkActive="active"><mat-icon>add_a_photo</mat-icon><span>Photos</span></a>-->
<!--      <a mat-list-item routerLink="stuff" routerLinkActive="active"><mat-icon>inventory_2</mat-icon><span>Inventaire</span></a>-->
      <a mat-list-item routerLink="wishlist" routerLinkActive="active"><mat-icon>history_edu</mat-icon><span>Idées Cadeaux</span></a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-nav-list class="tools" *ngIf="!auth.isLogged">
      <a mat-list-item routerLink="login" routerLinkActive="active"><mat-icon>login</mat-icon><span>Login</span></a>
<!--      <a mat-list-item routerLink="register" routerLinkActive="active"><mat-icon>drive_file_rename_outline</mat-icon><span>Inscription</span></a>-->
    <mat-divider></mat-divider>
  </mat-nav-list>


  </mat-sidenav>
  <mat-sidenav-content [class.sidenav-open]="(isHandset$ | async) === false">
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
<!--      <span><a mat-icon-button routerLink=""><mat-icon>home</mat-icon></a></span>-->

      <ng-container *ngIf="currentCircle$ | async as circle">
        <span>{{circle.name}}</span>
        <span><a mat-icon-button routerLink="circle/edit"> <mat-icon>edit</mat-icon></a></span>
      </ng-container>


      <span class="spacer"></span>
      <a mat-icon-button routerLink="user/profile" *ngIf="auth.isLogged"><mat-icon>settings</mat-icon></a>
<!--      <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">-->
<!--        <mat-icon>share</mat-icon>-->
<!--      </button>-->

      <button *ngIf="!auth.isLogged"
        mat-icon-button class="example-icon xfavorite-icon"
        id="qsLoginBtn"
          (click)="loginWithRedirect()"
        >
        <mat-icon>login</mat-icon>
      </button>
      <button *ngIf="auth.isLogged"
        mat-icon-button class="example-icon xfavorite-icon"
        id="qsLogoutBtn"
        (click)="logout()"
        >
        <mat-icon>logout</mat-icon>
      </button>
    </mat-toolbar>
    <!-- Add Content Here -->

        <router-outlet></router-outlet>

<!--        <router-outlet name="pouet"></router-outlet>-->

  </mat-sidenav-content>
</mat-sidenav-container>
