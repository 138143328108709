import {Component, OnDestroy, OnInit} from "@angular/core";
import {CreateMessage, MessagesFlow} from "../model/message";
import {createMessage} from "../store/actions/message.actions";
import {FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Circle} from "../model/circle";
import {Observable, Subject} from "rxjs";
import {getCurrentCircle} from "../store/reducers";
import {debounceTime, filter, takeUntil} from "rxjs/operators";
import {PicturesService} from "../services/pictures.service";

@Component({
  selector: 'message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss']
})
export class MessageFormComponent implements OnInit, OnDestroy {

  public circle$: Observable<Circle> = this.store.select(getCurrentCircle);
  public thumbnail: string;
  public error: any;
  public fileUploading: boolean;

  public messageForm = new FormGroup({
    content: new FormControl("", []),
  });

  private circle: Circle;
  private files: string[] = [];

  private destroy$ = new Subject();

  constructor(private store: Store<MessagesFlow>,
              private picturesService: PicturesService) { }

  ngOnInit(): void {
    this.circle$.pipe(
      filter(circle => circle !== undefined),
      debounceTime(1000),
      takeUntil(this.destroy$)
    ).subscribe(circle => {
      this.circle = circle
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public sendMessage() {
    let content = this.messageForm.getRawValue();
    const message = {...content, circleUid: this.circle.uid, files: this.files} as CreateMessage;
    this.store.dispatch(createMessage({message}));
    this.messageForm.reset();
    this.thumbnail = undefined;
    this.error = undefined;
    this.files = [];
  }

  public onFileChange($event) {
    const photo = $event.target.files[0];
    let formData = new FormData();
    formData.append("picture", photo, photo.name);
    this.fileUploading = true;
    this.picturesService.upload(formData)
      .subscribe((
        response) => {
          this.thumbnail = `${PicturesService.url}/thumb/${response.filename}` ;
          this.files.push(response.filename);
        },
        error => {
          console.error("Upload failed", error);
          this.error = error.message;

        }, () => {
          this.fileUploading = false;
        });
  }

}
