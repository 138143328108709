<ng-container *ngIf="circle$ | async as circle">

  <mat-card *ngIf="circle.editable; else circleReadOnlyTemplate">
    <mat-card-title>Modifier Groupe '{{circle.name}}'</mat-card-title>

    <mat-card-content>
      <form [formGroup]="circleForm">
        <p class="mat-error" *ngIf="error">{{error}}</p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput placeholder="" formControlName="name" required>
            <mat-error *ngIf="circleForm.controls.name.invalid">Name required</mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-checkbox formControlName="membersOnly">Salon privé?</mat-checkbox>
        </p>
        <p *ngIf="this.circleForm.get('membersOnly').value === true">
          <mat-selection-list #members formControlName="members">
            <mat-list-option *ngFor="let user of users$ | async" [value]="user.id">{{user.username}}</mat-list-option>
          </mat-selection-list>
        </p>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <div>
        <a mat-button routerLink="/circle">Quitter</a>
        <button mat-raised-button color="primary" [disabled]="!circleForm.valid" (click)="save()">Sauvegarder</button>
      </div>
      <div>
        <button mat-raised-button color="" (click)="delete()">Supprimer</button>
      </div>
    </mat-card-actions>
  </mat-card>




  <ng-template #circleReadOnlyTemplate>
    <mat-card>
      <mat-card-title>Groupe '{{circle.name}}'</mat-card-title>
      <mat-card-content>
        <span>Membres:</span>
        <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let member of members$ | async">{{member.username}} ({{member.email}})</mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </ng-template>

</ng-container>




