<mat-card>
  <mat-card-header>
    <div mat-card-avatar class="logo"></div>
    <mat-card-title>Magic link!</mat-card-title>
    <mat-card-subtitle>Mot de passe oublié? Problème pour te connecter?</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>
      Si tu n'arrives pas/plus à te connecter, donne moi ton email et tu vas recevoir un lien "magique" par mail.
    </p>
    <mat-form-field class="example-full-width">
      <mat-label>Email</mat-label>
      <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
             placeholder="Ex. pat@example.com">
<!--      <mat-hint>Errors appear instantly!</mat-hint>-->
      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-error *ngIf="responseNok">
      <p>Aïe! Désolé! Tu ne peux pas recevoir un "lien magique"! 😫</p>
      <p>Probablement parceque ton email n'est pas dans ma liste... 😥</p>
    </mat-error>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button mat-raised-button color="primary" (click)="send()" [disabled]="!emailFormControl.valid"><mat-icon>auto_awesome</mat-icon>Recevoir par mail</button>
  </mat-card-actions>
</mat-card>


