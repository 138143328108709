import { Component, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {NewCircleDialogComponent} from "./new-circle-dialog.component";
import {Store} from "@ngrx/store";
import {addCircle} from "../../store/actions/circle.actions";
import {CreateCircle} from "../../model/circle";
import {Router} from "@angular/router";

@Component({
  selector: 'new-circle',
  templateUrl: './new-circle.component.html',
  styleUrls: ['./new-circle.component.scss']
})
export class NewCircleComponent implements OnInit {

  public data: CreateCircle = {name: undefined};

  constructor(public dialog: MatDialog,
              private store: Store,
              private router: Router) { }

  ngOnInit(): void {
  }

  newCircle() {
    const dialogRef = this.dialog.open(NewCircleDialogComponent, {
      width: '250px',
      data: this.data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.store.dispatch(addCircle({circle:this.data}));
      this.data = {name: undefined};
      this.router.navigate(['circle']);
    });
  }

}
