import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Route, Router} from "@angular/router";
import {UserService} from "../services/user.service";
import {AuthService} from "../shared/auth.service";
import {Store} from "@ngrx/store";
import {Circles} from "../model/circle";
import {loadCircles} from "../store/actions/circle.actions";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(route: ActivatedRoute,
              userService: UserService,
              authService: AuthService,
              private store: Store<Circles>,
              private router: Router) {


    //   this.userService.authenticate(credential).


  }

  ngOnInit(): void {
    this.store.dispatch(loadCircles());
  }

}
