import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {


  constructor() {
  }

  ngOnInit() {
  }



}
