<mat-nav-list>

  <a href="https://docs.google.com/" mat-list-item (click)="reply($event)">
    <span mat-line>Repondre au message de {{this.data.message.from}}</span>
  </a>

  <a mat-list-item (click)="delete($event)" *ngIf="this.data.message.removable">
    <span mat-line>Supprimer ce message</span>
  </a>

<!--  <a href="https://plus.google.com/" mat-list-item (click)="onLink($event)">-->
<!--    <span mat-line>Agenda</span>-->
<!--    <span mat-line>Lier un evenement</span>-->
<!--  </a>-->
</mat-nav-list>
