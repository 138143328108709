<svg viewBox="0 0 64 64">
  <defs>

    <g id="arrow-down">
      <path
        d="M0.2,10.2c-0.3,0.3-0.3,0.5,0,0.8l15.2,14.4c0.3,0.3,0.8,0.3,1,0L31.7,11c0.3-0.3,0.3-0.5,0-0.8l-3.1-2.9c-0.3-0.3-0.5-0.3-0.8,0L16.2,18.6c-0.3,0.3-0.5,0.3-0.8,0L3.9,7.4c-0.3-0.3-0.5-0.3-0.8,0L0.2,10.2z"/>
    </g>

    <g id="valuation">
      <path d="M6,32H0V8h6V32z M26,24.4h-6V32h6V24.4z M10,17v15h6V20.6C13.6,20,11.6,18.8,10,17z M18.8,0c5,0,9,4,9,9
    	c0,1.6-0.4,3.2-1.4,4.8l5,5c0.8,0.8,0.8,2,0,2.8c-0.8,0.8-2,0.8-2.8,0l-5-5c-1.4,0.8-3,1.4-4.8,1.4C14,17.8,10,13.8,10,8.8
    	C10,4,14,0,18.8,0z M18.8,15.6c3.6,0,6.6-3,6.6-6.6s-3-6.6-6.6-6.6s-6.6,3-6.6,6.6C12.4,12.6,15.2,15.6,18.8,15.6z M22.6,10.4
    	c0-0.4-0.2-1-0.4-1.4C22,8.6,21.6,8.4,21,8.2c-0.6,0-1-0.2-1.6-0.2c-0.4,0-0.6-0.2-1-0.2C18.2,7.6,18,7.4,18,7.4
    	c0-0.4,0.4-0.6,1-0.6c0.8,0,1.6,0.2,2.2,0.6l0.8-2c-0.8-0.4-1.6-0.6-2.4-0.6V3.4h-1.4v1.2c-0.8,0.2-1.6,0.6-2.2,1
    	c-0.4,0.6-0.6,1.2-0.6,1.8c0,0.4,0.2,1,0.4,1.4c0.2,0.4,0.6,0.6,1,0.8C17.4,9.8,18,10,18.4,10s0.8,0.2,1,0.2c0.2,0,0.4,0.2,0.4,0.4
    	c0,0.2,0,0.2-0.2,0.4c-0.2,0-0.6,0.2-0.8,0.2c-0.4,0-1,0-1.4-0.2s-0.8-0.4-1.2-0.6l-0.8,2c0.2,0.2,0.6,0.4,1.2,0.6s1.2,0.2,1.6,0.2
    	v1.2h1.6v-1.2c0.8-0.2,1.4-0.4,2-1C22.4,11.8,22.6,11.2,22.6,10.4z"/>
    </g>

    <g id="risk">
      <path d="M16,0L1.6,5.2v13.2C1.6,25.2,13.5,32,16,32c2.5,0,14.4-6.8,14.4-13.6V5.2L16,0z M16,25.6c-1.4,0-2.5-1.2-2.5-2.4
    	c0-1.2,1.2-2.4,2.5-2.4s2.5,1.2,2.5,2.4C18.5,24.4,17.4,25.6,16,25.6z M18.5,16c0,1.4-1.2,2.4-2.5,2.4s-2.5-1-2.5-2.4V8
    	c0-1.4,1.2-2.4,2.5-2.4s2.5,1.2,2.5,2.4V16z"/>
    </g>

    <g id="bonds" class="bonds" viewBox="0 0 64 64">
      <path id="circle" class="circle" d="M31.65,0a23.2,23.2,0,1,0,23.2,23.2A23.22,23.22,0,0,0,31.65,0Zm0,40a16.8,16.8,0,1,1,16.8-16.8A16.9,16.9,0,0,1,31.65,40Z"/>
      <path id="ribbon" d="M43.65,46V64l-12-10.4L19.65,64V46a24.16,24.16,0,0,0,12,2.8A24.16,24.16,0,0,0,43.65,46Z"/>
      <path id="dollar"
            d="M29.25,11.2c0-2.8,4.4-2.8,4.4,0v2a8,8,0,0,1,3.2,1.6c2.4,2.4,1.2,4.8-.8,3.2l-2-1.2c-2.8-1.6-4.4,1.2-2.4,2.4l3.6,2.4c5.2,3.2,4,10.8-1.6,11.2v1.6c0,2.8-4.4,2.8-4.4,0V32.8l-2.4-1.2c-4.4-2.6-3.4-6.6,1.6-4s5-.1,2.4-2-3.2-2.4-3.2-2.4c-4.7-3.7-3.7-8.7,1.6-10Z"/>
    </g>


    <symbol id="rounded-bonds">
      <g id="circle">
        <path
          d="M32.13,10.74A15.47,15.47,0,1,0,47.6,26.21,15.48,15.48,0,0,0,32.13,10.74Zm0,26.67a11.2,11.2,0,1,1,11.2-11.2A11.27,11.27,0,0,1,32.13,37.41Z"
        />
      </g>
      <g id="ribbon">
        <path d="M40.13,41.41v12l-8-6.93-8,6.93v-12a16.15,16.15,0,0,0,8,1.87A16.13,16.13,0,0,0,40.13,41.41Z"/>
      </g>
      <g id="dollar">
        <path
          d="M30.53,18.21a1.47,1.47,0,0,1,2.94,0v1.33a5.42,5.42,0,0,1,2.13,1.07c1.6,1.6.8,3.2-.53,2.13l-1.34-.8c-1.86-1.06-2.93.8-1.6,1.6l2.4,1.6c3.47,2.14,2.67,7.2-1.06,7.47v1.07a1.47,1.47,0,0,1-2.94,0V32.61l-1.6-.8C26,30.08,26.67,27.41,30,29.14s3.33-.06,1.6-1.33-2.13-1.6-2.13-1.6c-3.14-2.47-2.47-5.8,1.06-6.67Z"
        />
      </g>
    </symbol>


  </defs>
</svg>
