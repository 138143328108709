import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wtf',
  templateUrl: './wtf.component.html',
  styleUrls: ['./wtf.component.scss']
})
export class WtfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
