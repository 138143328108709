import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PicturesService} from "../services/pictures.service";

@Component({
  selector: 'message-file-view-dialog',
  templateUrl: './message-file-view-dialog.component.html',
  styleUrls: ['./message-file-view-dialog.component.scss']
})
export class MessageFileViewDialogComponent  {

  constructor(
    public dialogRef: MatDialogRef<MessageFileViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {file: string}) {}

  close(): void {
    this.dialogRef.close();
  }

  public pictureSrc(file: string): string {
    return `${PicturesService.url}/${file}`;
  }

}
