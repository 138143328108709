<mat-horizontal-stepper  #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Nom de ce canal</ng-template>
      <mat-form-field>
        <mat-label>Nom</mat-label>
        <input matInput placeholder="Nom de ce canal?" formControlName="nameCtrl" required>
      </mat-form-field>
      <div>
        <button mat-button matStepperNext>Suivant</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup" label="Membres">
    <form [formGroup]="secondFormGroup">
      <mat-form-field>
        <mat-label>Membres</mat-label>

        <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
               required>

      </mat-form-field>
      <div>
        <button mat-button matStepperPrevious>Retour</button>
        <button mat-button matStepperNext>Terminer</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <p>You are now done.</p>
    <div>
      <button mat-button matStepperPrevious>Retour</button>
      <button mat-button (click)="stepper.reset()">Annuler</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
