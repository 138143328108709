import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {User} from "../model/user";
import {Profile} from "../model/profile";
import {Friend} from "../model/friend";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public authenticate(credentials: {email: string, password: string}): Observable<User> {
    return this.http.post<User>(`${environment.apiUri}/users/login`, credentials);
  }

  public authenticateWithLaisserPasser(laisserpasser: string): Observable<User> {
    return this.http.post<User>(`${environment.apiUri}/users/laisserpasser`, {}, {
      headers: {
        'x-laisserpasser': laisserpasser }
    });
  }

  public logout(): Observable<void> {
    return this.http.get<void>(`${environment.apiUri}/users/logout`);
  }

  public register(credentials: {username: string, email: string, password: string}): Observable<User> {
    return this.http.post<User>(`${environment.apiUri}/users/register`, credentials);
  }

  public invite(invitation: {email: string}): Observable<User> {
    return this.http.post<User>(`${environment.apiUri}/users/invite`, invitation);
  }

  public magiclink(request: {email: string}): Observable<User> {
    return this.http.post<User>(`${environment.apiUri}/users/magiclink`, request);
  }

  public getAll(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUri}/users`);
  }

  public getRoles(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUri}/users/roles`);
  }

  public getFriends(): Observable<Friend[]> {
    return this.http.get<Friend[]>(`${environment.apiUri}/users/friends`);
  }

  public password(newPassword: string): Observable<any> {
    return this.http.put<any>(`${environment.apiUri}/users/password`, {}, {
      headers: {
        // 'x-old-password': oldPassword,
        'x-new-password': newPassword }
    });
  }

  public save(profile: Profile): Observable<Profile> {
    return this.http.put<Profile>(`${environment.apiUri}/users/profile`, profile);
  }

  public get(profile: Profile): Observable<Profile> {
    return this.http.get<Profile>(`${environment.apiUri}/users/profile`);
  }
}
