import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {MyErrorStateMatcher} from "../shared/error-state-matcher";


@Component({
  selector: 'invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  public emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  public messageFormControl = new FormControl('', [Validators.maxLength(2000)]);

  matcher = new MyErrorStateMatcher();

  constructor() { }

  ngOnInit(): void {

  }

  public invite() {

  }

  public cancel() {

  }
}
