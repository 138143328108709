<mat-card *ngIf="viewDate$ | async  as viewDate">
  <mat-card-title>Agenda</mat-card-title>
  <mat-card-actions>
    <div>
      <a mat-raised-button color="primary" routerLink="event">Ajouter un événement</a>
    </div>
    <div>
      <button mat-stroked-button (click)="previous()">Previous</button>
      <button mat-stroked-button (click)="today()">Today</button>
      <button mat-stroked-button (click)="next()">Next</button>
    </div>
    <div>
      <mat-button-toggle-group>
        <mat-button-toggle (click)="viewMonth()">Month</mat-button-toggle>
        <mat-button-toggle (click)="viewWeek()">Week</mat-button-toggle>
        <mat-button-toggle (click)="viewDay()">Day</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </mat-card-actions>
  <mat-card-content>
    <h3>{{ viewDate | date }}</h3>

    <ng-container *ngIf="view$ | async as calendarView">
      <mwl-calendar-month-view
        *ngIf="calendarView === AgendaView.MONTH"
        [viewDate]="viewDate$ | async"
        [events]="events$ | async"
        [refresh]="refresh$"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-month-view>
      <mwl-calendar-week-view
        *ngIf="calendarView === AgendaView.WEEK"
        [viewDate]="viewDate"
        [events]="events$ | async"
        [refresh]="refresh$"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        *ngIf="calendarView === AgendaView.DAY"
        [viewDate]="viewDate"
        [events]="events$ | async"
        [refresh]="refresh$"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-day-view>
    </ng-container>


  </mat-card-content>
  <mat-card-actions>
    <a mat-raised-button color="primary" routerLink="event">Ajouter un événement</a>
  </mat-card-actions>
</mat-card>
