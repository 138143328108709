import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import {Circles} from "../../model/circle";
import {circlesReducer} from "./circle.reducers";
import {MessagesFlow} from "../../model/message";
import {messagesReducer} from "./message.reducers";
import {eventsReducer} from "./event.reducers";
import {Agenda} from "../../model/agenda";
import {StuffsState} from "../stuff-state";
import {stuffsReducer} from "./stuff.reducers";

export interface State {
  circles: Circles; // all available circles
  messages: MessagesFlow;
  agenda: Agenda;
  stuff: StuffsState
}

// Selector functions
const getCirclesFeatureState = createFeatureSelector<Circles>("circles");

export const getCircles = createSelector(
  getCirclesFeatureState,
  state => state.circles
);

export const getCircleNotifications = createSelector(
  getCirclesFeatureState,
  state => state.notification
);

const getMessagesFeatureState = createFeatureSelector<MessagesFlow>("messages");

export const getMessages = createSelector(
  getMessagesFeatureState,
  state => state.messages
);

export const getCurrentCircle = createSelector(
  getMessagesFeatureState,
  state => state.circle
);

const getAgendaFeatureState = createFeatureSelector<Agenda>("agenda");

export const getEvents = createSelector(
  getAgendaFeatureState,
  state => state.events
);

export const getAgendaDate = createSelector(
  getAgendaFeatureState,
  state => state.date
);

export const getAgendaView = createSelector(
  getAgendaFeatureState,
  state => state.view
);

export const reducers: ActionReducerMap<State> = {
  circles: circlesReducer,
  messages: messagesReducer,
  agenda: eventsReducer,
  stuff: stuffsReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
