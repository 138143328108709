import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import {loadStuffs} from "../../store/actions/stuff.action";
import {Observable} from "rxjs";
import {Stuff} from "../../model/stuff";
import {selectAllStuffs} from "src/app/store/selectors/stuff.selectors";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-stuff-list',
  templateUrl: './stuff-list.component.html',
  styleUrls: ['./stuff-list.component.scss']
})
export class StuffListComponent implements OnInit {

  public stuffs$: Observable<Stuff[]> = this.store.select(selectAllStuffs).pipe(
    tap(x => console.log('pouet', x))
  );

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(loadStuffs());
  }

}
