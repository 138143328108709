import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {userLogged, userLogin, userLogout, userRejected, userUnlogged} from "../actions/user.action";
import {UserService} from "../../services/user.service";
import {AuthService} from "../../shared/auth.service";


@Injectable()
// @ts-ignore
export class UserEffects {

  userLogin$ = createEffect(() => this.actions$.pipe(
    ofType(userLogin),
    mergeMap((action) => this.userService.authenticate(action)
      .pipe(
        map((user)  => userLogged({user})),
        catchError((error) => of(userRejected({ error: error.error })))
      ))
    )
  );

  userLogout$ = createEffect(() => this.actions$.pipe(
      ofType(userLogout),
      mergeMap((action) => {
        this.authService.logout();
        return this.userService.logout()
          .pipe(
            map((user)  => userUnlogged()),
            catchError((error) => of(userRejected({ error: error.error })))
          )
      })
    )
  );

  // selectCircle$ = createEffect(() => this.actions$.pipe(
  //   ofType(selectCircle),
  //   mergeMap((action) => this.userService.selectCircle(action)
  //     .pipe(
  //       map((user)  => userLogged({user})),
  //       catchError((error) => of(userRejected({ error: error.error })))
  //     ))
  //   )
  // );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private authService: AuthService
  ) {}
}
